import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Web3 from "web3";
import DataSourceSelectorModal from "../DataSourceSelectorModal";
import { useWeb3Context } from "../../contexts/Web3Context";
import IFAllocationMaster from "../../utils/abi/IFAllocationMaster.json";
import { getNetworkLink } from "../../utils/parse";
import { checkError } from "../../utils/checkError";

const getUTC = (date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();
  const [trackId, setTrackId] = useState();
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState("{}");
  const [dstChainId, setDstChainId] = useState();
  const [srcMasterContract, setSrcMasterContract] = useState("");
  const [dstMasterContract, setDstMasterContract] = useState("");
  const [whitelistArr, setWhitelistArr] = useState("");
  const [snapshotTimeVal, setSnapshotTimeVal] = useState(new Date());
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Modal state
  const [isModalOpen, setModalOpen] = useState(false);
  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const handleOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const [selectedSale, setSelectedSale] = useState(undefined);

  const handleImport = useCallback(() => {
    if (!selectedSale) return;
    setSrcMasterContract(selectedSale.masterAddress);
    setTrackId(selectedSale.trackId);
    setDstChainId(selectedSale.saleChainId);
    setModalOpen(false);
    const allocTime = new Date(selectedSale.subscribePeriod.endTime);
    setSnapshotTimeVal(getUTC(allocTime));
  }, [selectedSale, setModalOpen]);

  const deploy = async (e) => {
    e.preventDefault();
    setResult("");
    setError("");
    if (!provider) return;
    try {
      const snapshotTime =
        Date.parse(snapshotTimeVal.toString().split(" G")[0] + "z") / 1000;

      setIsLoading(true);
      const web3 = new Web3(provider);
      // whitelisted addresses array
      let whitelistArr1 = [],
        whitelistArr2 = [];

      // input array check
      if (whitelistArr !== "") {
        whitelistArr1 = whitelistArr.split(",\n");
      }
      // json upload check
      if (JSON.parse(fileData) !== []) {
        whitelistArr2 = JSON.parse(fileData) || [];
      }

      let parsedArr = whitelistArr1.length ? whitelistArr1 : whitelistArr2;
      parsedArr = await parsedArr.map((s) => s.toLowerCase());

      const allocationMasterContract = new web3.eth.Contract(
        IFAllocationMaster.abi,
        srcMasterContract
      );

      // set sale token allocation override
      let txhash = await allocationMasterContract.methods
        .syncUserWeight(
          dstMasterContract,
          parsedArr,
          trackId,
          snapshotTime,
          dstChainId
        )
        .send({
          from: account,
          value: web3.utils.toWei(
            (0.01 + 0.00008 * parsedArr.length).toString(),
            "ether"
          ),
        });
      setResult(txhash.transactionHash);

      // set sale token allocation override
      await allocationMasterContract.methods
        .syncTotalWeight(dstMasterContract, trackId, snapshotTime, dstChainId)
        .send({
          from: account,
          value: web3.utils.toWei((0.005).toString(), "ether"),
        });

      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  };

  const handleFile = (e) => {
    e.preventDefault();
    try {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      setFileName(e.target.files[0].name);
      fileReader.onload = (e) => {
        // console.log("e.target.result", e.target.result);
        setFileData(e.target.result);
      };
    } catch (err) {
      setFileName("");
      setFileData("{}");
    }
  };

  return (
    <section className={classes.details}>
      <DataSourceSelectorModal
        isModalOpen={isModalOpen}
        handleClose={handleClose}
        selectedSale={selectedSale}
        setSelectedSale={setSelectedSale}
        handleImport={handleImport}
      />
      <div className={classes.inputContainer}>
        <Button
          onClick={handleOpen}
          variant="contained"
          color="primary"
          type="button"
        >
          Import Data
        </Button>
      </div>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Source Master Contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={srcMasterContract}
            onChange={(e) => setSrcMasterContract(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Track ID</label>
          <input
            type="number"
            placeholder="0"
            value={trackId}
            onChange={(e) => setTrackId(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Destination Chain ID</label>
          <input
            type="number"
            placeholder="56"
            value={dstChainId}
            onChange={(e) => setDstChainId(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">
            Destination Adapter Master Contract address
          </label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={dstMasterContract}
            onChange={(e) => setDstMasterContract(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Snapshot Time (UTC)</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="MM/dd/yyyy"
                value={snapshotTimeVal}
                onChange={(data) => setSnapshotTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={snapshotTimeVal}
                onChange={(data) => setSnapshotTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">Addresses Array</label>
          <textarea
            type="text"
            value={whitelistArr}
            placeholder="0xABCD...,
0xBCDE...,
0xCDEF..."
            onChange={(e) => setWhitelistArr(e.target.value)}
            style={{ width: "-webkit-fill-available" }}
          />
        </div>

        <label className={classes.label} htmlFor="upload-file">
          Upload
        </label>
        <div className={classes.drag}>
          <input id="upload-file" onChange={handleFile} type="file" hidden />
          <p>
            {" "}
            <label htmlFor="upload-file">Browse file</label>
          </p>
          <br />
          <p>
            {fileName ? (
              fileName
            ) : (
              <>
                Upload a json file with array of objects like{" "}
                {"['0xABCD', '0xBCDE']"}
              </>
            )}
          </p>
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${
            isLoading && classes.btnWithLoader
          }`}
        >
          {isLoading ? "Verifying..." : "Bridging"}
          {isLoading && (
            <CircularProgress className={`${classes.loading}`} size={24} />
          )}
        </Button>
      </form>

      {result && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.success}>
            Bridge Allocation Successful {" → "}
            <a
              href={`${getNetworkLink(providerChainId)}/tx/${result}`}
              target="blank"
              className={classes.link}
            >
              {result}
            </a>
          </p>
        </div>
      )}

      {error && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.error}>{error}</p>
        </div>
      )}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: 1200,
    margin: "auto",
    textAlign: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: "black",
    marginTop: 30,
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  drag: {
    width: "80%",
    margin: "0 auto 20px auto",
    borderRadius: "6px",
    border: "1px dashed #C7CBD9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "60px 10px",

    "& p": {
      textAlign: "center",
      color: "#939bba",
      fontSize: "14px",
      fontWeight: "600",
      margin: "0",

      "& label": {
        color: "#8247e5",
        textDecoration: "underline",
        cursor: "pointer",

        "&:hover": {
          color: "#7533e2",
        },
      },
    },
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all",
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline",
  },
  error: {
    color: "red",
  },
}));

export default Form;
