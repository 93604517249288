import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";
import { getNetworkLink } from "../../utils/parse";
import { checkError } from "../../utils/checkError";
import IFAllocationSale from "../../utils/abi/IFAllocationSale.json";

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();

  const [allocationSale, setAllocationSale] = useState('');
  // const [delay, setDelay] = useState('');
  const [newEndTime, setNewEndTime] = useState(new Date());
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const deploy = async (e) => {
    e.preventDefault()
    setResult('');
    setError('');
    if (!provider) return;
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      const allocationSaleContract = new web3.eth.Contract(IFAllocationSale.abi, allocationSale);
      // old sale end time = [end time + delay]
      const lastEndTimeNow = Number(await allocationSaleContract.methods.endTime().call());
      const oldDelay = Number(await allocationSaleContract.methods.withdrawDelay().call());

      const newEndTimeNow = Date.parse(newEndTime.toString().split(' G')[0] + 'z') / 1000;
      // calculate delay in timestamp
      const newDelay = newEndTimeNow - lastEndTimeNow;
      console.log({ lastEndTimeNow, newEndTimeNow, oldDelay, newDelay });
      // set sale token allocation override
      const txhash = await allocationSaleContract.methods.setWithdrawDelay(newDelay)
        .send({ from: account });
      setResult(txhash.transactionHash);

      // log
      console.log('Sale:', allocationSale)
      console.log('Withdraw Delay:', newDelay)
      console.log('---- Output ----')
      console.log('Tx hash:', txhash.transactionHash)
      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  return (
    <section className={classes.details}>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Allocation Sale Contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={allocationSale}
            onChange={(e) => setAllocationSale(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">New end time</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="MM/dd/yyyy"
                value={newEndTime}
                onChange={(data) => setNewEndTime(data)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={newEndTime}
                onChange={(data) => setNewEndTime(data)}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>


        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading ? "Verifying..." : "Delay Claim"}
          {isLoading && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      {result && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.success}>Delay Successful {' → '}
          <a href={`${getNetworkLink(providerChainId)}/tx/${result}`} target="blank" className={classes.link}>{result}</a>
        </p>
      </div>}

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;