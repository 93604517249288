import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";
import { getNetworkLink } from "../../utils/parse";
import { checkError } from "../../utils/checkError";
import IFAllocationSale from "../../utils/abi/IFAllocationSale.json";

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();

  const [allocationSale, setAllocationSale] = useState("");
  const [claimTime, setClaimTime] = useState([]);
  const [claimPct, setClaimPct] = useState([]);
  const [counter, setCounter] = useState(0);
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const addItem = useCallback(() => {
    const newClaimTime = [...claimTime];
    const newPct = [...claimPct];
    newClaimTime[counter] = new Date();
    newPct[counter] = null;
    setCounter(counter + 1);
    setClaimTime(newClaimTime);
    setClaimPct(newPct);
  }, [claimTime, claimPct, counter]);

  const deleteItem = useCallback(
    (idx) => {
      const newPct = [...claimPct];
      const newTime = [...claimTime];
      newPct.splice(idx, 1);
      newTime.splice(idx, 1);
      setClaimTime(newTime);
      setClaimPct(newPct);
      setCounter(counter - 1);
    },
    [claimTime, claimPct, counter]
  );

  const updateTime = (idx, value) => {
    const newClaimTime = [...claimTime];
    newClaimTime[idx] = value;
    setClaimTime(newClaimTime);
  };

  const updatePct = (idx, value) => {
    const newPct = [...claimPct];
    newPct[idx] = value;
    setClaimPct(newPct);
  };

  const deploy = async (e) => {
    e.preventDefault();
    setResult("");
    setError("");
    if (!provider) return;
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      const allocationSaleContract = new web3.eth.Contract(
        IFAllocationSale.abi,
        allocationSale
      );
      console.log(
        claimTime.map(
          (time) => Date.parse(time.toString().split(" G")[0] + "z") / 1000
        )
      );
      console.log(claimPct.map((val) => parseInt(val, 10)));
      // set sale token allocation override
      const txhash = await allocationSaleContract.methods
        .setCliffPeriod(
          claimTime.map(
            (time) => Date.parse(time.toString().split(" G")[0] + "z") / 1000
          ),
          claimPct.map((val) => parseInt(val, 10))
        )
        .send({ from: account });
      setResult(txhash.transactionHash);

      // log
      console.log("Sale:", allocationSale);
      console.log("Vesting Setup:", claimTime, claimPct);
      console.log("---- Output ----");
      console.log("Tx hash:", txhash.transactionHash);
      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <section className={classes.details}>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Allocation Sale Contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={allocationSale}
            onChange={(e) => setAllocationSale(e.target.value)}
            required
          />
        </div>
        <hr />
        {new Array(counter).fill(1).map((_, index) => (
          <div>
            <label htmlFor="id">Vest #{index + 1}</label>{" "}
            <button type="button" onClick={() => deleteItem(index)}>
              Remove
            </button>
            <div className={classes.inputContainer}>
              <label htmlFor="id">Vest Percentage</label>
              <input
                type="number"
                placeholder="10"
                value={claimPct[index]}
                onChange={(e) => updatePct(index, e.target.value)}
                required
              />
            </div>
            <div className={classes.inputContainer}>
              <label htmlFor="id">Vest Time</label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Date picker dialog"
                    format="MM/dd/yyyy"
                    value={claimTime[index]}
                    onChange={(data) => updateTime(index, data)}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label="Time picker"
                    value={claimTime[index]}
                    onChange={(data) => updateTime(index, data)}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
            <hr />
          </div>
        ))}

        <Box mb="18px">
          <Button type="button" onClick={addItem} variant="contained">
            Add more time
          </Button>
        </Box>
        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${
            isLoading && classes.btnWithLoader
          }`}
        >
          {isLoading ? "Verifying..." : "Setup Vesting"}
          {isLoading && (
            <CircularProgress className={`${classes.loading}`} size={24} />
          )}
        </Button>
      </form>

      {result && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.success}>
            Setup Vest Successful {" → "}
            <a
              href={`${getNetworkLink(providerChainId)}/tx/${result}`}
              target="blank"
              className={classes.link}
            >
              {result}
            </a>
          </p>
        </div>
      )}

      {error && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.error}>{error}</p>
        </div>
      )}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: 1200,
    margin: "auto",
    textAlign: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: "black",
    marginTop: 30,
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all",
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline",
  },
  error: {
    color: "red",
  },
}));

export default Form;
