import React from "react";
import { Routes, Route } from "react-router-dom";

import FetchData from "./pages/FetchData";
import SaleContract from "./pages/SaleContract";
import FundContract from "./pages/FundContract";
import SetWhitelist from "./pages/SetWhitelist";
import DelayClaim from "./pages/DelayClaim";
import MasterDeploy from "./pages/MasterDeploy";
import MasterAddTrack from "./pages/MasterAddTrack";
import TransferOwnership from "./pages/TransferOwnership";
import GenericToken from "./pages/GenericToken";
import SaleTokenOverride from "./pages/SaleTokenOverride";
import PublishIDO2 from "./pages/PublishIDO2";
import PostSaleStatsIDO2 from "./pages/PostSaleStatsIDO2";
import CheckSolvActionsIDO2 from "./pages/CheckSolvActionsIDO2";
import SetWhitelistIDO2 from "./pages/SetWhitelistIDO2";
import DeployFarmsFactory from "./pages/Farms/Factory";
import DeployFarmsMasterchef from "./pages/Farms/Masterchef";
import MasterSync from "./pages/MasterSync";
import AdapterMasterDeploy from "./pages/AdapterMasterDeploy";
import CliffVestClaim from "./pages/CliffVestClaim";

const App = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<FetchData />} />
        <Route path="/adapterdeploy" element={<AdapterMasterDeploy />} />
        <Route path="/saledeploy" element={<SaleContract />} />
        <Route path="/masterdeploy" element={<MasterDeploy />} />
        <Route path="/addtrack" element={<MasterAddTrack />} />
        <Route path="/erc20deploy" element={<GenericToken />} />
        <Route path="/fund" element={<FundContract />} />
        <Route path="/whitelist" element={<SetWhitelist />} />
        <Route path="/tokenoverride" element={<SaleTokenOverride />} />
        <Route path="/delay" element={<DelayClaim />} />
        <Route path="/cliffVestClaim" element={<CliffVestClaim />} />
        <Route path="/transfer" element={<TransferOwnership />} />
        <Route path="/publishido2" element={<PublishIDO2 />} />
        <Route path="/postsalestats" element={<PostSaleStatsIDO2 />} />
        <Route path="/checksolvactions" element={<CheckSolvActionsIDO2 />} />
        <Route path="/setwhitelistido2" element={<SetWhitelistIDO2 />} />
        <Route path="/mastersync" element={<MasterSync />} />
        <Route path="/farms/deploy/factory" element={<DeployFarmsFactory />} />
        <Route
          path="/farms/deploy/masterchef"
          element={<DeployFarmsMasterchef />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default App;
