import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Index = () => {
  const classes = useStyles();

  return (
    <section className={classes.details}>
      <Container className={classes.container}>
        <div className={classes.background}>
          <Typography variant="h1" className={classes.title}>
            Check Solv Actions for IDO2
          </Typography>

          <hr className={classes.hrTag} />
          <div className={classes.cont}>
            <Typography variant="h1" className={classes.smallTitle}>
              This script checks whether the ictoken can be used in an ido2 sale, whether the payment token is valid, and whether our address has access to change whitelist for this ic token
            </Typography>
            <Typography variant="h1" className={classes.smallTitle} style={{ fontWeight: 600 }}>
              Note: to check if solv has deployed ic token contract, check bsc scan or tenderly
            </Typography>
          </div>
        </div>
      </Container>
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.mui,
  details: {
    position: "relative",
    overflow: "hidden",
  },
  background: {
    width: '100%',
    margin: 'auto',
    height: '100%',
    borderRadius: 25,
    textAlign: 'center',
  },
  cont: {
    width: "70%",
    margin: "auto",
    "@media (max-width:600px)": {
      width: "95%",
    },
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    margin: '30px auto',
    "@media (max-width:600px)": {
      fontSize: 22,
      margin: '20px auto',
    },
  },
  hrTag: {
    height: 5,
    background: '#1F3C88',
    marginBottom: 20
  },
  smallTitle: {
    fontSize: 18,
    marginTop: 5,
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: 16,
    },
  },
  miniSmallTitle: {
    fontSize: 18,
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: 15,
    },
  },
}));

export default Index;
