import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  overrides: {
    formStyle: {
      formContainer: {
        padding: "40px 26px",
      },
      // input and label
      inputContainer: {
        width: "80%",
        margin: "30px auto",
        textAlign: 'left',

        "& input, & textarea": {
          height: "50px",
          borderRadius: 4,
          backgroundColor: "#fff",
          border: "1px solid #C7CBD9",
          padding: "0 15px",
          // lineHeight: "50px",
          fontSize: "16px",
          fontWeight: "500",
          width: "100%",
          color: "black",

          "&:active": {
            border: "1.5px solid #E84142",
            outline: "none",
          },
          "&:focus": {
            border: "1.5px solid #E84142",
            outline: "none",
          },
          "&:disabled": {
            pointerEvents: "none",
            userSelect: "none",
            backgroundColor: "transparent",
            position: "relative",

            "&~label": {
              color: "gray",
            },
          },
        },

        "& textarea": {
          padding: "15px",
          width: "115%",
          minHeight: 100,
          resize: 'vertical'
        },
        "& label": {
          fontSize: 16,
          fontWeight: "500",
          color: "#000",
          marginLeft: "3px",
          marginBottom: "4px",
          display: "block;",

          "& span": {
            color: "#515C72",
            fontSize: "14px",
            fontWeight: "normal",
          },
        },
      },
      label: {
        width: "80%",
        textAlign: "left",
        fontSize: 16,
        // color: "#E84142",
        margin: "4px auto",
        // marginBottom: "4px",
        // marginTop: 0,
        display: "block;",
      },
      // button
      btnContainer: {
        display: "flex",
        marginTop: "15px",
      },
      btn: {
        height: "44px",
        lineHeight: "44px",
        padding: "10px 20px",
        border: "2px solid #e84141",
        borderRadius: "4px",
        fontFamily: 'Inter,sans-serif',
        display: "inline-flex",
        textTransform: 'inherit',
        fontWeight: 400,
        fontSize: 16,
        position: "relative",

        "&:first-child": {
          marginRight: "14px",
        },
        "&:hover": {
          backgroundColor: "inherit"
        },

        "&:disabled": {
          backgroundColor: "#bdc3c7",
          borderColor: "#bdc3c7",
          // opacity: 0.65,
          color: "white",
        },
      },
      btnWithLoader: {
        paddingLeft: "44px",
        transition: "all 0.3s ease",
      },
      filled: {
        backgroundColor: "#e84142",
        color: "#fff",

        "&:hover": {
          backgroundColor: "#e84142",
        },
      },
      loading: {
        position: "absolute",
        display: "block",
        margin: "auto",
        left: "10px",
        color: "#E84142",
      },
    },
    mui: {
      container: {
        maxWidth: "1080px",
        margin: "auto",
        padding: "0",
        "@media (max-width:1120px)": {
          padding: "0 20px",
        },
        "@media (max-width:599px)": {
          padding: "0 15px",
        },
      },
    },
  },
  typography: {
    fontFamily: ['"Nunito Sans"', "sans-serif"].join(","),

    h1: {
      fontWeight: 400,
      fontSize: "2.5rem",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
  palette: {
    primary: {
      main: "#7167D9",
    },
  },
});

export default theme;