import React from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const Index = () => {
  const classes = useStyles();

  return (
    <section className={classes.details}>
      <Container className={classes.container}>
        <div className={classes.background}>
          <Typography variant="h1" className={classes.title}>
            Delay Claim
          </Typography>

          <hr className={classes.hrTag} />

          <div className={classes.cont}>
            <Typography variant="h1" className={classes.smallTitle}>
              DELAY is the number of timestamp. Select a new timestamp in GMT and the delay will be updated.
            </Typography>
          </div>
        </div>
      </Container>
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.mui,
  details: {
    position: "relative",
    overflow: "hidden",
  },
  background: {
    width: '100%',
    margin: 'auto',
    height: '100%',
    borderRadius: 25,
    textAlign: 'center',
  },
  cont: {
    width: "70%",
    margin: "auto",
    "@media (max-width:600px)": {
      width: "95%",
    },
  },
  title: {
    fontSize: 32,
    fontWeight: 700,
    margin: '30px auto',
    "@media (max-width:600px)": {
      fontSize: 22,
      margin: '20px auto',
    },
  },
  hrTag: {
    height: 5,
    background: '#1F3C88',
    marginBottom: 20
  },
  smallTitle: {
    fontSize: 18,
    marginTop: 5,
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: 16,
    },
  },
  miniSmallTitle: {
    fontSize: 18,
    fontWeight: 500,
    "@media (max-width:600px)": {
      fontSize: 15,
    },
  },
}));

export default Index;
