import BigNumber from "bignumber.js";

export const forMat = (number) => {
  return new BigNumber(number)
    .multipliedBy(new BigNumber(10 ** 18))
    .dividedToIntegerBy(new BigNumber(1))
    .dividedBy(new BigNumber(10 ** 18))
    .toNumber();
};

export const byDecimals = (number, tokenDecimals = 18) => {
  const decimals = new BigNumber(10).exponentiatedBy(tokenDecimals);
  return new BigNumber(number).dividedBy(decimals);
}

export const multipliedBy = (number, tokenDecimals = 18) => {
  const decimals = new BigNumber(10).exponentiatedBy(tokenDecimals);
  return new BigNumber(number).multipliedBy(decimals);
}

export const getBigNumber = (number) => {
  return new BigNumber(number);
}

export const toPlainString = (num) => {
  return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
    function (a, b, c, d, e) {
      return e < 0
        ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0);
    });
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getNetworkLink = (network) => {
  switch (network) {
    case 56:
      return "https://bscscan.com";
    case 97:
      return "https://testnet.bscscan.com";
    case 1285:
      return "https://moonriver.moonscan.io";
    case 1287:
      return "https://moonbase.moonscan.io";
    default:
      return "https://blockscan.com";
  }
}

export const MAX_INT = '20282409603651.670423947251286015';
