import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DataSourceSelectorModal from "../DataSourceSelectorModal";
import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";
import IFAllocationSale from "../../utils/abi/IFAllocationSale.json";
import { checkError } from "../../utils/checkError";
import {
  multipliedBy,
  toPlainString,
  numberWithCommas,
  MAX_INT,
  getNetworkLink,
} from "../../utils/parse";

// hardcoded const from the smart contract
const SALE_CONTRACT_DECIMALS = 18;

const getUTC = (date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();

  // Modal state
  const [isModalOpen, setModalOpen] = useState(false);
  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const handleOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);
  const [selectedSale, setSelectedSale] = useState(undefined);

  // main form state
  const [sellerAddr, setSellerAddr] = useState("");
  const [paymentToken, setPaymentToken] = useState("");
  const [paymentTokenDecimals, setPaymentTokenDecimals] = useState(18);
  const [saleToken, setSaleToken] = useState("");
  const [saleTokenDecimals, setSaleTokenDecimals] = useState(18);
  const [allocationMaster, setAllocationMaster] = useState("");
  const [trackId, setTrackId] = useState(0);
  const [snapshotTimeVal, setSnapshotTimeVal] = useState(new Date());
  const [startTimeVal, setStartTimeVal] = useState(new Date());
  const [endTimeVal, setEndTimeVal] = useState(new Date());
  const [salePriceVal, setSalePriceVal] = useState("0");
  const [salePriceWei, setSalePriceWei] = useState("0");
  const [maxTotalVal, setMaxTotalVal] = useState(MAX_INT);
  const [maxTotalWei, setMaxTotalWei] = useState(MAX_INT);

  const [txHash, setTxHash] = useState("");
  const [contractAddr, setContractAddr] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImport = useCallback(() => {
    if (!selectedSale) return;
    setPaymentToken(
      selectedSale.paymentToken?.address[selectedSale.chainId] || ""
    );
    setSaleToken(selectedSale.token.address);
    if (!selectedSale.saleChainId) {
      setAllocationMaster(selectedSale.masterAddress);
    }
    setTrackId(selectedSale.trackId);
    const allocTime = selectedSale.subscribePeriod?.endTime
      ? new Date(selectedSale.subscribePeriod?.endTime)
      : new Date();
    const startTime = selectedSale.purchasePeriod?.startTime
      ? new Date(selectedSale.purchasePeriod?.startTime)
      : new Date(
          new Date(selectedSale.subscribePeriod.endTime).getTime() + 10000
        );
    const endTime = new Date(
      selectedSale.purchasePeriod?.endTime ??
        selectedSale.claimPeriod?.startTime
    );

    setSnapshotTimeVal(getUTC(allocTime));
    setStartTimeVal(getUTC(startTime));
    setEndTimeVal(getUTC(endTime));
    setSaleTokenDecimals(parseInt(selectedSale.token?.decimals) || 18);
    setPaymentTokenDecimals(
      parseInt(selectedSale.paymentToken?.decimals) || 18
    );
    setSalePriceVal(selectedSale.purchasePeriod?.salePrice || "0");

    setModalOpen(false);
  }, [selectedSale, setModalOpen]);

  useEffect(() => {
    setSellerAddr(account);
  }, [account]);

  // calculate wei price
  useEffect(() => {
    // calculation of sale price is as follows
    // paymentToken * SALE_PRICE_DECIMALS /saleToken
    // e.g. 0.1 BUSD (18 decimals) per 1 SALE (8 decimals)
    // (0.1 * 10 ** 18) * (10 ** 18) / (10 ** 8) =
    // (1 * 10 ** 17) * (10 ** 18) / (10 ** 8) = (10 ** 27)
    setSalePriceWei(
      toPlainString(
        multipliedBy(
          salePriceVal,
          SALE_CONTRACT_DECIMALS + paymentTokenDecimals - saleTokenDecimals
        ).toString()
      )
    );
  }, [salePriceVal, paymentTokenDecimals, saleTokenDecimals]);

  const deploy = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      let allocationSaleContract = new web3.eth.Contract(IFAllocationSale.abi);
      const snapshotTime =
        Date.parse(snapshotTimeVal.toString().split(" G")[0] + "z") / 1000;
      const startTime =
        Date.parse(startTimeVal.toString().split(" G")[0] + "z") / 1000;
      const endTime =
        Date.parse(endTimeVal.toString().split(" G")[0] + "z") / 1000;

      const maxTotal = web3.utils.toWei(maxTotalVal, "ether");
      await allocationSaleContract
        .deploy({
          data: IFAllocationSale.bytecode,
          arguments: [
            salePriceWei,
            sellerAddr,
            paymentToken,
            saleToken,
            allocationMaster,
            trackId,
            snapshotTime,
            startTime,
            endTime,
            maxTotal,
          ],
        })
        .send({ from: account })
        .on("transactionHash", (transactionHash) => {
          console.log(transactionHash);
          setTxHash(transactionHash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log(receipt.contractAddress);
          setContractAddr(receipt.contractAddress);
        });

      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <section className={classes.details}>
      <DataSourceSelectorModal
        isModalOpen={isModalOpen}
        handleClose={handleClose}
        selectedSale={selectedSale}
        setSelectedSale={setSelectedSale}
        handleImport={handleImport}
      />
      <div className={classes.inputContainer}>
        <Button
          onClick={handleOpen}
          variant="contained"
          color="primary"
          type="button"
        >
          Import Data
        </Button>
      </div>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Seller addresses</label>
          <input
            type="text"
            value={sellerAddr}
            onChange={(e) => setSellerAddr(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Payment token decimals</label>
          <input
            type="number"
            value={paymentTokenDecimals}
            onChange={(e) => setPaymentTokenDecimals(parseInt(e.target.value))}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Payment token address</label>
          <input
            type="text"
            value={paymentToken}
            onChange={(e) => setPaymentToken(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Sale token decimals</label>
          <input
            type="number"
            value={saleTokenDecimals}
            onChange={(e) => setSaleTokenDecimals(parseInt(e.target.value))}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Sale token address</label>
          <input
            type="text"
            value={saleToken}
            onChange={(e) => setSaleToken(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">
            Allocation Master address / Adapter Address for cross sale
          </label>
          <input
            type="text"
            value={allocationMaster}
            onChange={(e) => setAllocationMaster(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Track Id</label>
          <input
            type="text"
            value={trackId}
            onChange={(e) => setTrackId(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Snapshot Time</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="MM/dd/yyyy"
                value={snapshotTimeVal}
                onChange={(data) => setSnapshotTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={snapshotTimeVal}
                onChange={(data) => setSnapshotTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Start Time</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="MM/dd/yyyy"
                value={startTimeVal}
                onChange={(data) => setStartTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={startTimeVal}
                onChange={(data) => setStartTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">End Time</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="MM/dd/yyyy"
                value={endTimeVal}
                onChange={(data) => setEndTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={endTimeVal}
                onChange={(data) => setEndTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Sale price</label>
          <input
            type="text"
            value={salePriceVal}
            onChange={(e) => {
              setSalePriceVal(e.target.value);
            }}
            required
          />
          <label style={{ color: "#2C394B", fontSize: 14 }}>
            wei: {numberWithCommas(salePriceWei)}
          </label>
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Max total payment</label>
          <input
            type="text"
            value={maxTotalVal}
            onChange={(e) => {
              setMaxTotalVal(e.target.value);
              try {
                let web3 = new Web3(provider);
                setMaxTotalWei(web3.utils.toWei(e.target.value, "ether"));
              } catch (err) {
                console.error("Max total stake has many decimals", err);
                setMaxTotalWei(
                  toPlainString(multipliedBy(e.target.value).toString())
                );
              }
            }}
            required
          />
          <label style={{ color: "#2C394B", fontSize: 14 }}>
            wei: {numberWithCommas(maxTotalWei)}
          </label>
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${
            isLoading && classes.btnWithLoader
          }`}
        >
          {isLoading ? "Verifying..." : "Deploy"}
          {isLoading && (
            <CircularProgress className={`${classes.loading}`} size={24} />
          )}
        </Button>
      </form>

      {txHash && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.success}>
            Tx hash {" → "}
            <a
              href={`${getNetworkLink(providerChainId)}/tx/${txHash}`}
              target="blank"
              className={classes.link}
            >
              {txHash}
            </a>
          </p>
        </div>
      )}

      {contractAddr && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.success}>
            Deployed contract address {" → "}
            <a
              href={`${getNetworkLink(
                providerChainId
              )}/address/${contractAddr}`}
              target="blank"
              className={classes.link}
            >
              {contractAddr}
            </a>
          </p>
        </div>
      )}

      {error && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.error}>{error}</p>
        </div>
      )}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: 1200,
    margin: "auto",
    textAlign: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: "black",
    marginTop: 30,
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all",
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline",
  },
  error: {
    color: "red",
  },
}));

export default Form;
