import React from "react";

import Navbar from "../components/Navbar";
import HeroSection from '../components/FundContract/Hero';
import Form from '../components/FundContract/Form';
import Footer from "../components/Footer";

const FundContract = () => {
  return (
    <React.Fragment>
      <Navbar />
      <main style={{ minHeight: '80vh' }}>
        <HeroSection />
        <Form />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default FundContract;