import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";
import { multipliedBy, toPlainString, numberWithCommas, getNetworkLink } from "../../utils/parse";
import { checkError } from "../../utils/checkError";
import IFAllocationSale from "../../utils/abi/IFAllocationSale.json";

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();

  const [allocationSale, setAllocationSale] = useState('');
  const [saleTokenAllocation, setSaleTokenAllocation] = useState('0');
  const [saleTokenAllocationWei, setSaleTokenAllocationWei] = useState('0');
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [tokenDecimals, setTokenDecimals] = useState(18);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSaleTokenAllocationWei(
      toPlainString(
        multipliedBy(saleTokenAllocation, tokenDecimals).toString()
      )
    );
  }, [saleTokenAllocation, tokenDecimals])

  const deploy = async (e) => {
    e.preventDefault()
    setResult('');
    setError('');
    if (!provider) return;
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      const allocationSaleContract = new web3.eth.Contract(IFAllocationSale.abi, allocationSale);
      // set sale token allocation override
      let txhash = await allocationSaleContract.methods.setSaleTokenAllocationOverride(saleTokenAllocationWei)
        .send({ from: account });
      setResult(txhash.transactionHash);

      // log
      console.log('Sale:', allocationSale)
      console.log('allocation:', saleTokenAllocationWei)
      console.log('---- Output ----')
      console.log('Tx hash:', txhash.transactionHash)
      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  return (
    <section className={classes.details}>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Allocation sale contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={allocationSale}
            onChange={(e) => setAllocationSale(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Sale token decimals</label>
          <input
            type="number"
            value={tokenDecimals}
            onChange={(e) => setTokenDecimals(parseInt(e.target.value))}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label>Allocation</label>
          <input
            type="number"
            placeholder="100000000000000000"
            value={saleTokenAllocation}
            onChange={(e) => {
              setSaleTokenAllocation(e.target.value);
            }}
            required
          />
          <label style={{ color: "#2C394B", fontSize: 14 }}>wei: {numberWithCommas(saleTokenAllocationWei)}</label>
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading ? "Verifying..." : "Override"}
          {isLoading && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      {result && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.success}>Sale token override successful {' → '}
          <a href={`${getNetworkLink(providerChainId)}/tx/${result}`} target="blank" className={classes.link}>{result}</a>
        </p>
      </div>}

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;