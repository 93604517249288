import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { AppBar, Container } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";

// import useOuterClick from '../hooks/useOuterClick';
import ConnectWallet from "./ConnectWallet";
import { useWeb3Context } from '../contexts/Web3Context';

const Navbar = () => {
  const classes = useStyles();
  const { providerChainId } = useWeb3Context();

  // to toggle the menu
  const [openMenu, setOpenMenu] = useState(false);
  const menuItemContainerRef = useRef(null);
  const toggleMenu = (state) => {
    state
      ? menuItemContainerRef.current.classList.add("open")
      : menuItemContainerRef.current.classList.remove("open");
    setOpenMenu(state);
  };
  const [showSaleMenu, setShowSaleMenu] = useState(false);
  const [showMasterMenu, setShowMasterMenu] = useState(false);
  const [, setShowIdo2Menu] = useState(false);
  const [showFarmMenu, setShowFarmMenu] = useState(false);
  
  return (
    <AppBar position="static" classes={{ root: classes.nav }}>
      <Container className={classes.container}>
        <div className={classes.flexContainer}>
          <NavLink to="/" className={classes.imgCont}>
            <img src="/logo.svg" alt="logo" className={classes.logo} />
          </NavLink>

          <div className={classes.navContainer}>
            <div
              onClick={() => { setShowSaleMenu(wasOpened => !wasOpened); setShowMasterMenu(false); setShowIdo2Menu(false); }}
              style={{ cursor: 'pointer' }}>
              Sale Contract ↓
            </div>
            {showSaleMenu && <div className={classes.dropDown}>
              <NavLink exact to="/saledeploy" className="menuItem">Sale Deploy</NavLink>
              <NavLink exact to="/fund" className="menuItem">Fund contract</NavLink>
              <NavLink exact to="/whitelist" className="menuItem">Whitelist address</NavLink>
              <NavLink exact to="/delay" className="menuItem">Delay claim</NavLink>
              <NavLink exact to="/cliffVestClaim" className="menuItem">Cliff Vesting claim</NavLink>
              <NavLink exact to="/tokenoverride" className="menuItem">Token Override</NavLink>
              <NavLink exact to="/transfer" className="menuItem">Transfer contract</NavLink>
            </div>}

            <div
              onClick={() => { setShowMasterMenu(wasOpened => !wasOpened); setShowSaleMenu(false); setShowIdo2Menu(false); }}
              style={{ cursor: 'pointer' }}>
              Master Contract ↓
            </div>
            {showMasterMenu && <div className={`${classes.dropDown} ${classes.leftGap}`}>
              <NavLink exact to="/masterdeploy" className="menuItem">Master Deploy</NavLink>
              <NavLink exact to="/addtrack" className="menuItem">Add track</NavLink>
              <NavLink exact to="/mastersync" className="menuItem">Master Sync</NavLink>
              <NavLink exact to="/adapterdeploy" className="menuItem">Adapter Master Deploy</NavLink>
            </div>}

            <div
              onClick={() => { setShowFarmMenu(wasOpened => !wasOpened); setShowSaleMenu(false); setShowMasterMenu(false); setShowIdo2Menu(false); }}
              style={{ cursor: 'pointer' }}>
              Farms ↓
            </div>
            {showFarmMenu && <div className={`${classes.dropDown} ${classes.leftGap}`}>
              <NavLink exact to="/farms/deploy/factory" className="menuItem">Deploy Factory</NavLink>
              <NavLink exact to="/farms/deploy/masterchef" className="menuItem">Deploy Masterchef</NavLink>
            </div>}

            {/* <div
              onClick={() => { setShowIdo2Menu(wasOpened => !wasOpened); setShowSaleMenu(false); setShowMasterMenu(false); }}
              style={{ cursor: 'pointer' }}>
              IDO2 ↓
            </div>
            {showIdo2Menu && <div className={`${classes.dropDown} ${classes.leftGap}`}>
              <NavLink exact to="/checksolvactions" className="menuItem">Check Solv Actions</NavLink>
              <NavLink exact to="/publishido2" className="menuItem">Publish IDO2</NavLink>
              <NavLink exact to="/setwhitelistido2" className="menuItem">Set Whitelist IDO2</NavLink>
              <NavLink exact to="/postsalestats" className="menuItem">Setup post sale stats</NavLink>
            </div>} */}

            <NavLink exact to="/erc20deploy" className="menuItem">erc20 deploy</NavLink>

            <ConnectWallet />
          </div>

          <MenuIcon className={classes.menuIcon} onClick={() => openMenu ? toggleMenu(false) : toggleMenu(true)} />
        </div>
        <div className={classes.menuItemContainer} ref={menuItemContainerRef}>
          <NavLink exact to="/saledeploy" className="menuItem">Sale Deploy</NavLink>
          <NavLink exact to="/fund" className="menuItem">Fund contract</NavLink>
          <NavLink exact to="/whitelist" className="menuItem">Whitelist address</NavLink>
          <NavLink exact to="/delay" className="menuItem">Delay claim</NavLink>
          <NavLink exact to="/transfer" className="menuItem">Transfer contract</NavLink>
          <NavLink exact to="/masterdeploy" className="menuItem">Master Deploy</NavLink>
          <NavLink exact to="/addtrack" className="menuItem">Add track</NavLink>
          <NavLink exact to="/erc20deploy" className="menuItem">erc20 deploy</NavLink>
        </div>
        {providerChainId &&
          <div style={{ position: 'absolute', top: 65, right: 10, color: 'black' }}>
            chain - {providerChainId}
          </div>}
      </Container>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.mui,
  nav: {
    height: 60,
    position: "relative",
    boxShadow: "none",
    backgroundColor: "#1F3C88",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgCont: {
    width: 144,
    "@media (max-width:700px)": {
      width: 'max-content',
    },
  },
  logo: {
    height: 35,
    marginRight: 10,
    "@media (max-width:700px)": {
      height: 30,
    },
  },
  menuItemContainer: {
    display: "none",
    "@media (max-width:599px)": {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      backgroundColor: "white",
      width: "100%",
      top: 60,
      left: 0,
      padding: 0,
      height: 0,
      overflow: "hidden",
      transition: "all 0.5s ease",
    },

    "&.open": {
      padding: "5px 0",
      height: "auto",
      transition: "all 0.5s ease",
    },

    "& .menuItem": {
      fontSize: "16px",
      textDecoration: "none",
      fontFamily: 'Inter,sans-serif',
      borderBottom: '1px solid #1F3C88',
      fontWeight: 400,

      "&:hover": {
        textDecoration: "underline",
      },

      "@media (max-width:599px)": {
        margin: 0,
        color: "#1F3C88",
        textAlign: "center",
        lineHeight: "50px",
      },
    },
  },
  navContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
    maxWidth: 500,
    width: '100%',
    textDecoration: 'underline',
    "@media (max-width:599px)": {
      display: "none",
    },
  },
  dropDown: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    backgroundColor: "#EAEAEA",
    width: "max-content",
    top: 46,
    borderRadius: 10,
    padding: '0px 16px',
    overflow: "hidden",
    transition: "all 0.5s ease",
    "&.open": {
      display: "flex",
    },
    "&:onBlur": {
      display: "none",
    },
    "& .menuItem": {
      fontSize: 14,
      textDecoration: "none",
      lineHeight: "36px",
      fontFamily: 'Inter,sans-serif',
      borderTop: '1px solid #1F3C88',
      fontWeight: 400,
      color: '#1F3C88',

      "&:hover": {
        textDecoration: "underline",
      },

      "@media (max-width:599px)": {
        margin: 0,
        color: "#1F3C88",
        textAlign: "center",
        lineHeight: "50px",
      },
    },
    "@media (max-width:599px)": {
      position: "static",
      width: "100%",
      padding: "10px 0",
      height: "auto",
      transition: "all 0.5s ease",
    },
  },
  leftGap: {
    left: '22%',
  },
  menuIcon: {
    display: "none",
    "@media (max-width:599px)": {
      display: "block",
      marginLeft: "20px",
      marginTop: "6px",
    },
  },
}));

export default Navbar;
