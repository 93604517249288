import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SERVER_ORIGIN = {
  staging: {
    label: "staging",
    url: "https://stg-config-cms.impossible.finance/api/projects?bypassCode=bypass",
  },
  production: {
    label: "production",
    url: "https://config-cms.impossible.finance/api/projects?bypassCode=bypass",
  },
};

const DataSourceSelectorModal = ({
  isModalOpen,
  handleClose,
  setSelectedSale,
  selectedSale,
  handleImport,
}) => {
  const [origin, setOrigin] = useState(undefined);
  const [sales, setSales] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      fetch(SERVER_ORIGIN[origin].url)
        .then((response) => response.json())
        .then((data) => {
          setSales(
            data
              .flatMap((value) => value.sales)
              .map((value) => ({
                label: `${value.id} - ${value.title}`,
                value,
              }))
          );
        });
    };
    if (origin) {
      fetchData();
    }
  }, [origin, setSales]);

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Project Importer
        </Typography>
        <Box mt="16px">
          <Typography id="modal-modal-title" variant="body2">
            Choose data origin
          </Typography>
          <FormControl fullWidth>
            <InputLabel>Data Origin</InputLabel>
            <Select
              value={origin}
              input={<OutlinedInput label="Name" />}
              onChange={(evt) => {
                setOrigin(evt.target.value);
              }}
            >
              <MenuItem value={SERVER_ORIGIN.staging.label}>
                {SERVER_ORIGIN.staging.label}
              </MenuItem>
              <MenuItem value={SERVER_ORIGIN.production.label}>
                {SERVER_ORIGIN.production.label}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {origin && (
          <Box mt="8px">
            <Typography id="modal-modal-title" variant="body2">
              Choose project to import
            </Typography>
            <Autocomplete
              disablePortal
              options={sales}
              sx={{ width: 300 }}
              onChange={(_, value) => setSelectedSale(value.value)}
              renderInput={(params) => (
                <TextField {...params} label="Project" />
              )}
            />
          </Box>
        )}
        {selectedSale && (
          <Box mt="8px">
            <Button
              onClick={handleImport}
              variant="contained"
              color="primary"
              type="button"
            >
              Import
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default DataSourceSelectorModal;
