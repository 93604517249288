import React from "react";

import Navbar from "../components/Navbar";
import HeroSection from "../components/CliffVestClaim/Hero";
import Form from "../components/CliffVestClaim/Form";
import Footer from "../components/Footer";

const DelayClaim = () => {
  return (
    <React.Fragment>
      <Navbar />
      <main style={{ minHeight: "80vh" }}>
        <HeroSection />
        <Form />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default DelayClaim;
