import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { useSearchParams } from 'react-router-dom';

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";
import { toPlainString, multipliedBy, numberWithCommas, getNetworkLink } from "../../utils/parse";
import IFAllocationSale from "../../utils/abi/IFAllocationSale.json";
import { checkError } from "../../utils/checkError";
import erc20 from "../../utils/abi/erc20.json";

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();

  const [allocationSale, setAllocationSale] = useState('');
  const [saleTokenAddress, setSaleTokenAddress] = useState('');

  const [amount, setAmount] = useState('0');
  const [result, setResult] = useState('');
  const [amountInWei, setAmountInWei] = useState('0');
  const [decimals, setDecimals] = useState(18);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setAmount(searchParams.get('amount'));
    setAllocationSale(searchParams.get('address'));
    setSaleTokenAddress(searchParams.get('token'));
    const decimalsParam = parseInt(searchParams.get('decimals')) || 18
    setDecimals(decimalsParam);
    setAmountInWei(toPlainString(multipliedBy(searchParams.get('amount'), decimalsParam).toString()));
  }, [searchParams]);

  const deploy = async (e) => {
    e.preventDefault()
    setResult('');
    setError('');
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      const allocationSaleContract = new web3.eth.Contract(IFAllocationSale.abi, allocationSale);

      // get original saleAmount
      const originalSaleAmount = await allocationSaleContract.methods.saleAmount().call();
      // get sale token address

      const saleTokenAddr = saleTokenAddress;
      console.log({ originalSaleAmount, saleTokenAddr });
      const saleTokenContract = new web3.eth.Contract(erc20.abi, saleTokenAddr);

      // check allowance
      const allowance = await saleTokenContract.methods.allowance(account, allocationSale).call();
      console.log("allowance", allowance);
      if (Number(allowance) < Number(amountInWei)) {
        // tx1 - approve
        const txhash = await saleTokenContract.methods.approve(allocationSale, amountInWei).send({ from: account });
        console.log("approve", txhash.transactionHash);
      }
      console.log('allowance is enough');
      // tx2 - fund
      const txhash = await allocationSaleContract.methods.fund(amountInWei).send({ from: account });
      console.log(txhash.transactionHash);
      setResult(txhash.transactionHash);
      // get new saleAmount
      const newSaleAmount = await allocationSaleContract.methods.saleAmount().call();

      // log
      console.log('Sale:', allocationSale)
      console.log('Amount:', amount)
      console.log('---- Output ----')
      // console.log('Tx hash:', txhash.hash)
      console.log('Original sale amount:', originalSaleAmount)
      console.log('New sale amount:', newSaleAmount)
      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  return (
    <section className={classes.details}>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Allocation Sale Contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={allocationSale}
            onChange={(e) => setAllocationSale(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Sale Token Contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={saleTokenAddress}
            onChange={(e) => setSaleTokenAddress(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">Decimals</label>
          <input
            type="number"
            value={decimals}
            onChange={(e) => {
              setDecimals(e.target.value);
              setAmountInWei(toPlainString(multipliedBy(amount, e.target.value || 18).toString()));
            }}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => {
              setAmount(e.target.value);
              setAmountInWei(toPlainString(multipliedBy(e.target.value, decimals).toString()));
            }}
            required
          />
          <label style={{ color: "#2C394B", fontSize: 14 }}>wei: {numberWithCommas(amountInWei)}</label>
        </div>


        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading ? "Verifying..." : "Fund"}
          {isLoading && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      {result && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.success}>Fund Successful {' → '}
          <a href={`${getNetworkLink(providerChainId)}/tx/${result}`} target="blank" className={classes.link}>{result}</a>
        </p>
      </div>}

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;