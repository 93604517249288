import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";
import IFAllocationSale from "../../utils/abi/IFAllocationSale.json";
import { computeMerkleRoot } from "../../utils/merkleWhitelist";
import { getNetworkLink } from "../../utils/parse";
import { checkError } from "../../utils/checkError";

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();

  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState("{}");
  const [allocationSale, setAllocationSale] = useState('');
  const [whitelistArr, setWhitelistArr] = useState('');
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const deploy = async (e) => {
    e.preventDefault()
    setResult('');
    setError('');
    if (!provider) return;
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      // whitelisted addresses array
      let whitelistArr1 = [], whitelistArr2 = [];

      // input array check
      if (whitelistArr !== '') {
        whitelistArr1 = whitelistArr.split(",\n");
      }
      // json upload check
      if (JSON.parse(fileData) !== []) {
        whitelistArr2 = JSON.parse(fileData) || [];
      }
      // console.log({ whitelistArr1, whitelistArr2 });

      let parsedArr = whitelistArr1.length ? whitelistArr1 : whitelistArr2
      parsedArr = await parsedArr.map((s) => s.toLowerCase());
      // get merkle root
      const merkleRoot = computeMerkleRoot(parsedArr);
      console.log({ parsedArr, merkleRoot });

      const allocationSaleContract = new web3.eth.Contract(IFAllocationSale.abi, allocationSale);
      // set sale token allocation override
      let txhash = await allocationSaleContract.methods.setWhitelist(merkleRoot)
        .send({ from: account });
      setResult(txhash.transactionHash);

      // log
      console.log('Sale:', allocationSale);
      console.log('allocation:', parsedArr, merkleRoot);
      console.log('---- Output ----');
      console.log('Tx hash:', txhash.transactionHash);
      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  const handleFile = (e) => {
    e.preventDefault();
    try {
      const fileReader = new FileReader();
      fileReader.readAsText(e.target.files[0], "UTF-8");
      setFileName(e.target.files[0].name);
      fileReader.onload = e => {
        // console.log("e.target.result", e.target.result);
        setFileData(e.target.result);
      };
    } catch (err) {
      setFileName("");
      setFileData("{}");
    }
  }

  return (
    <section className={classes.details}>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Allocation Sale Contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={allocationSale}
            onChange={(e) => setAllocationSale(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">Whitelist Array</label>
          <textarea
            type="text"
            value={whitelistArr}
            placeholder="0xABCD...,
0xBCDE...,
0xCDEF..."
            onChange={(e) => setWhitelistArr(e.target.value)}
            style={{ width: '-webkit-fill-available' }}
          />
        </div>

        <label className={classes.label} htmlFor="upload-file">
          Upload
        </label>
        <div className={classes.drag}>
          <input id="upload-file" onChange={handleFile} type="file" hidden />
          <p> <label htmlFor="upload-file">Browse file</label></p>
          <br />
          <p>
            {fileName ? fileName
              : <>Upload a json file with array of objects like {"['0xABCD', '0xBCDE']"}</>}
          </p>
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading ? "Verifying..." : "Set Whitelist"}
          {isLoading && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      {result && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.success}>Whitelist Successful {' → '}
          <a href={`${getNetworkLink(providerChainId)}/tx/${result}`} target="blank" className={classes.link}>{result}</a>
        </p>
      </div>}

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  drag: {
    width: '80%',
    margin: "0 auto 20px auto",
    borderRadius: "6px",
    border: "1px dashed #C7CBD9",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "60px 10px",

    "& p": {
      textAlign: "center",
      color: "#939bba",
      fontSize: "14px",
      fontWeight: "600",
      margin: "0",

      "& label": {
        color: "#8247e5",
        textDecoration: "underline",
        cursor: "pointer",

        "&:hover": {
          color: "#7533e2",
        },
      },
    },
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;