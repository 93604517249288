import React, { useState, useEffect } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";

import SolvMarketSale from "../../utils/abi/SolvICMarket.json"
import { checkError } from "../../utils/checkError";
import { marketAddresses } from "../../utils/constants";

const Form = () => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const { account, provider, providerChainId } = useWeb3Context();
  const [isLoading, setIsLoading] = useState(false);

  const [result, setResult] = useState([])
  const [icTokenAddr, setIcTokenAddr] = useState('')
  const [paymentTokenAddr, setPaymentTokenAddr] = useState('') 
  const [marketAddr, setMarketAddr] = useState('')
   
  useEffect(() => {
    setMarketAddr(marketAddresses[providerChainId])
  }, [providerChainId])

  const isAddress = (address) => (/^(0x)?[0-9a-f]{40}$/i.test(address))

  const check = async (e) => {
    e.preventDefault()
    if (!provider) return;
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);

      if (!isAddress(marketAddr) || !isAddress(icTokenAddr) || !isAddress(paymentTokenAddr)) {
        const error = `${!isAddress(marketAddr) ? 'market' : !isAddress(icTokenAddr) ? 'ic token' : 'payment token'} address is not a valid EVM address`
        console.error(error)
        setError(error)
        setIsLoading(false)
      } else {
        const marketContract = new web3.eth.Contract(SolvMarketSale, marketAddr)
        const results = []
  
        const isTokenValid = await marketContract.methods.markets(icTokenAddr).call()
        results.push(isTokenValid[0] ? '\u2705 ic token is valid' : '\u274C ic token is not valid, ping solv and ask to set ictoken as a valid token in their market')
  
        const isCurrencyValid = await marketContract.methods.currencies(paymentTokenAddr).call()
        results.push(isCurrencyValid ? '\u2705 payment token is valid' : '\u274C payment token is not valid, ping solv and ask them to allow this payment token in their market')
  
        try {
          let i = 0
          while(1) {
            const address = await marketContract.methods.allowAddressManager(icTokenAddr, i).call()
            if (address === account) {
              results.push('\u2705 account is admin')
              break
            }
            i++
          }
        } catch(err) { results.push('\u274C account is not an admin, ping solv and ask to give whitelist admin permissions for this ic token') }
        setResult(results)
      }

      setIsLoading(false)
    } catch(err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  return (
    <section className={classes.details}>
      <form onSubmit={check} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Solv IC Token address for the sale token</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={icTokenAddr}
            onChange={(e) => setIcTokenAddr(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Market token address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={marketAddr}
            onChange={(e) => setMarketAddr(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Payment token address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={paymentTokenAddr}
            onChange={(e) => setPaymentTokenAddr(e.target.value)}
            required
          />
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading ? "Checking..." : "Check Steps"}
          {isLoading && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      <div></div>
      {result && result.map((e,i) => (
          <div key={i}>{e}</div>
        ))}

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
}


const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;