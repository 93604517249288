import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";
import { getNetworkLink } from "../../utils/parse";
import { checkError } from "../../utils/checkError";
import IFAllocationMasterAdapter from "../../utils/abi/IFAllocationMasterAdapter.json";

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();
  const [msgBussAddr, setMsgBussAddr] = useState();
  const [srcMasterContract, setSrcMasterContract] = useState();
  const [srcChainId, setSrcChainId] = useState();
  const [contractAddr, setContractAddr] = useState("");
  const [txHash, setTxHash] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const deploy = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      let allocationMasterContract = new web3.eth.Contract(
        IFAllocationMasterAdapter.abi
      );
      await allocationMasterContract
        .deploy({
          data: IFAllocationMasterAdapter.bytecode,
          arguments: [msgBussAddr, srcMasterContract, srcChainId],
        })
        .send({ from: account })
        .on("transactionHash", (transactionHash) => {
          console.log("transactionHash", transactionHash);
          setTxHash(transactionHash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("contractAddress", receipt.contractAddress);
          setContractAddr(receipt.contractAddress);
        });
      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <section className={classes.details}>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Message Bus Address</label>
          <input
            type="text"
            value={msgBussAddr}
            placeholder="0xABCD"
            onChange={(e) => setMsgBussAddr(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">Source master address</label>
          <input
            type="text"
            value={srcMasterContract}
            placeholder="0xABCD"
            onChange={(e) => setSrcMasterContract(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">Source Chain ID</label>
          <input
            type="number"
            value={srcChainId}
            placeholder="56"
            onChange={(e) => setSrcChainId(e.target.value)}
            required
          />
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${
            isLoading && classes.btnWithLoader
          }`}
        >
          {isLoading ? "Verifying..." : "Deploy Master"}
          {isLoading && (
            <CircularProgress className={`${classes.loading}`} size={24} />
          )}
        </Button>
      </form>

      {txHash && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.success}>
            Tx hash {" → "}
            <a
              href={`${getNetworkLink(providerChainId)}/tx/${txHash}`}
              target="blank"
              className={classes.link}
            >
              {txHash}
            </a>
          </p>
        </div>
      )}

      {contractAddr && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.success}>
            Deployed contract address {" → "}
            <a
              href={`${getNetworkLink(
                providerChainId
              )}/address/${contractAddr}`}
              target="blank"
              className={classes.link}
            >
              {contractAddr}
            </a>
          </p>
        </div>
      )}

      {error && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.error}>{error}</p>
        </div>
      )}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: 1200,
    margin: "auto",
    marginTop: 50,
    textAlign: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: "black",
    marginTop: 30,
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all",
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline",
  },
  error: {
    color: "red",
  },
}));

export default Form;
