import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <figure className={classes.backgroundImage}>
        <img src="/img/background.svg" className={classes.backgroundImg} alt="background" />
      </figure>
      <div>
        <p className={classes.text}>
          Copyright © 2021, Impossible.Finance.
        </p>
      </div>
    </footer >
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.mui,
  footer: {
    position: "relative",
    marginTop: 20,
    zIndex: 2,
    borderTop: '2px solid #1F3C88',
    display: 'block',
    padding: "25px 0",
  },
  backgroundImage: {
    margin: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    height: '100%',
    width: '100%',
  },
  backgroundImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  text: {
    fontSize: 15,
    margin: 'auto',
    fontWeight: 500,
    textAlign: 'center'
  },
}));

export default Footer;
