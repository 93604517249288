import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";

import Web3 from "web3";
import { byDecimals, forMat } from "../../utils/parse";
import { useWeb3Context } from "../../contexts/Web3Context";
import { checkError } from "../../utils/checkError";
import IFAllocationMaster from "../../utils/abi/IFAllocationMaster.json";

const Form = () => {
  const classes = useStyles();
  const { provider } = useWeb3Context();

  const [allocationMaster, setAllocationMaster] = useState('0x1D37f1e6F0CcE814F367d2765eBad5448e59B91b');
  const [trackId, setTrackId] = useState(0);
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const fetch = async (e) => {
    e.preventDefault()
    setError('');
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      const allocationSaleContract = new web3.eth.Contract(IFAllocationMaster.abi, allocationMaster);
      const numTrackStakers = await allocationSaleContract.methods.numTrackStakers(trackId).call();
      let trackMaxStakes = await allocationSaleContract.methods.trackMaxStakes(trackId).call();
      const trackCheckpointCounts = await allocationSaleContract.methods.trackCheckpointCounts(trackId).call();
      const trackCheckpoints = await allocationSaleContract.methods.trackCheckpoints(trackId, trackCheckpointCounts - 1).call();
      console.log({ numTrackStakers, trackMaxStakes, trackCheckpointCounts, trackCheckpoints });
      // format data
      trackMaxStakes = byDecimals(trackMaxStakes);
      let totalStakeWeight = byDecimals(trackCheckpoints.totalStakeWeight);
      let totalStaked = byDecimals(trackCheckpoints.totalStaked);
      setData({ numTrackStakers, trackMaxStakes, trackCheckpointCounts, totalStakeWeight, totalStaked });

      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  return (
    <section className={classes.details}>
      <form onSubmit={fetch} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Master contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={allocationMaster}
            onChange={(e) => setAllocationMaster(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Track Id</label>
          <input
            type="number"
            value={trackId}
            onChange={(e) => setTrackId(e.target.value)}
            required
          />
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading ? "fetching..." : "Fetch Data"}
          {isLoading && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      {data.numTrackStakers &&
        <div style={{ width: '100%', textAlign: 'center' }}>
          <p className={classes.success}>Number of track stakers {' → '} {data.numTrackStakers}</p>
          <p className={classes.success}>Max Staked {' → '} {forMat(data.trackMaxStakes)}</p>
          <p className={classes.success}>Total Staked {' → '} {forMat(data.totalStaked)}</p>
          <p className={classes.success}>Total Stake Weight {' → '} {forMat(data.totalStakeWeight)}</p>
        </div>
      }

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;