import React from "react";

import Navbar from "../../../components/Navbar";
import HeroSection from "./Components/HeroSection";
import Form from "./Components/Form";
import Footer from "../../../components/Footer";

const FarmFactoryDeploy = () => {
  return (
    <React.Fragment>
      <Navbar />
      <main style={{ minHeight: "80vh" }}>
        <HeroSection />
        <Form />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default FarmFactoryDeploy;
