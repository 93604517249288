import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography, Grid, Select, MenuItem } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from "@material-ui/core/styles";
import BigNumber from "bignumber.js";

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";

import erc20 from "../../utils/abi/erc20.json";
import { getNetworkLink } from "../../utils/parse";
import SolvMarketSale from "../../utils/abi/SolvICMarket.json"
import SolvICToken from "../../utils/abi/SolvICToken.json"
import { checkError } from "../../utils/checkError";
import { marketAddresses, vestingPoolAddresses } from "../../utils/constants";

const modifyArrElem = (arr, pos, newElem) => {
  const arr2 = JSON.parse(JSON.stringify(arr))
  newElem ? arr2.splice(pos, 1, newElem) : arr2.splice(pos, 1)
  return arr2
}

const parseDate = (date) => parseInt(typeof date === 'object' ? date.getTime()/1000 : Date.parse(date)/1000, 10)

const intToWei = (int) => new BigNumber(int).multipliedBy(Math.pow(10, 18))

const Form = () => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const { account, provider, providerChainId } = useWeb3Context();
  const [isLoading, setIsLoading] = useState(false);
  
  const [isFixedPriceSale, setIsFixedPriceSale] = useState(true) // is the sale fixed or declining price

  const Steps  = {
    A: 'Approve Sale Token Transfer',
    B: 'Mint Solv NFT',
    C: 'Approve Solv NFT Transfer',
    D: `Publish ${isFixedPriceSale ? 'Fixed' : 'Declining'} Price Sale`
  }

  const [pageState, setPageState] = useState(Steps.A);
  const [result, setResult] = useState([]);

  const [saleTokenAddr, setSaleTokenAddr] = useState('') // address of the token to sell
  const [icTokenAddr, setIcTokenAddr] = useState('') // address of IC token/solv's nft associated with the sale token
  const [saleAmount, setSaleAmount] = useState() // amount of sale token to sell

  const [useVesting, setUseVesting] = useState(false) // using vesting?
  const [maturities, setMaturities] = useState([new Date()]) // Array of timestamps
  const [percentages, setPercentages] = useState([0]) // Array of percentage of tokens released at each stage, in basis pts. Sums to 10000 
  const [tokenId, setTokenId] = useState(0) // token id associated with the nft

  const [paymentTokenAddr, setPaymentTokenAddr] = useState('') // address of payment token
  const [min, setMin] = useState(); // min purchase per addr
  const [max, setMax] = useState(); // max purchase per addr

  const [startTime, setStartTime] = useState(new Date())
  const [endTime, setEndTime] = useState(new Date())

  const [startPrice, setStartPrice] = useState() // sale start price not wei
  const [lowestPrice, setLowestPrice] = useState() // lowest price it'll go to not wei
  const [interval, setInterval] = useState() // interval between price drops. lower interval = less staircasey, more slopey
  const [intervalUnits, setIntervalUnits] = useState(1) // interval between price drops. lower interval = less staircasey, more slopey
  const [useAllowList, setUseAllowList] = useState(true) // whitelisted sale or not
  
  const [startBlock, setStartBlock] = useState() // block number when sale starts
  const [saleId, setSaleId] = useState()

  const [vestingPoolAddr, setVestingPoolAddr] = useState('')
  const [marketAddr, setMarketAddr] = useState('')

  useEffect(() => {
    setMarketAddr(marketAddresses[providerChainId])
    setVestingPoolAddr(vestingPoolAddresses[providerChainId])
  }, [providerChainId])

  const isAddress = (address) => (/^(0x)?[0-9a-f]{40}$/i.test(address))

  const approve = async (e) => {
    e.preventDefault()
    if (!provider) return;
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);

      if (!isAddress(saleTokenAddr)) {
        const error = "Sale token address is not a valid EVM address"
        console.error(error)
        setError(error)
        setIsLoading(false)
      }

      if (!isAddress(vestingPoolAddr)) {
        const error = "IDO2 only supported on BSC or BSC testnet"
        console.error(error)
        setError(error)
        setIsLoading(false)
      }

      const saleTokenContract = new web3.eth.Contract(erc20.abi, saleTokenAddr)

      // check allowance
      const allowance = new BigNumber(await saleTokenContract.methods.allowance(account, vestingPoolAddr).call());
      console.log("allowance", allowance.toString());

      const saleAmountInWei = intToWei(saleAmount)

      if (allowance.isLessThan(saleAmountInWei)) {
        // tx1 - approve
        await saleTokenContract.methods.approve(vestingPoolAddr, saleAmountInWei).send({ from: account })
        .on('transactionHash', (txhash) => {
          setResult([...result, { step: pageState, hash: txhash }])
        })
        .on('confirmation', async () => {
          console.log("approved token transfer");
          setPageState(Steps.B);
          setIsLoading(false);
        })
      } else {
        console.log('allowance is sufficient');
        setPageState(Steps.B);
        setIsLoading(false);
      }
    } catch(err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  const mint = async (e) => {
    e.preventDefault()
    setError('')
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      
      if (!isAddress(icTokenAddr)) {
        const error = "IC token address is not a valid EVM address"
        console.error(error)
        setError(error)
        setIsLoading(false)
      }

      const icToken = new web3.eth.Contract(SolvICToken, icTokenAddr)

      const icTokensOwned = await icToken.methods.balanceOf(account).call()

      if (useVesting && percentages.reduce((a, b) => a+b, 0) !== 10000) {
        const error = `Percentages need to sum to 10000, currently its ${percentages.reduce((a, b) => a+b, 0)}`;
        console.error(error);
        setError(error);
        setIsLoading(false);
      } else if (useVesting && maturities.length >= 2 && maturities.reduce((a, v) => a!==false && parseDate(a) <= parseDate(v) ? v : false, 0)) {
        const error = "Vesting time is not in ascending order";
        console.error(error);
        setError(error);
        setIsLoading(false);
      } else {
        await icToken.methods.mint(
          useVesting ? (parseDate(maturities[maturities.length-1])-parseDate(maturities[0])) : 0,
          intToWei(saleAmount),
          useVesting ? maturities.map(e => parseDate(e)) : [0],
          useVesting ? percentages : [10000],
          "Impossible Finance Launchpad Sale"
        ).send({ from: account })
        .on('transactionHash', (txhash) => {
          setResult([...result, { step: pageState, hash: txhash }])
        })
        .on('confirmation', async () => {
          setTokenId((await icToken.methods.tokenOfOwnerByIndex(account, icTokensOwned).call()))
          console.log("minted solv nft")
          setPageState(Steps.C)
          setIsLoading(false)
        })
      }
    } catch(err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  const prepublish = async (e) => {
    e.preventDefault()
    setError('')
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      const icToken = new web3.eth.Contract(SolvICToken, icTokenAddr)
      if (!isAddress(marketAddr)) {
        const error = "Market token address is not a valid EVM address"
        console.error(error)
        setError(error)
        setIsLoading(false)
      }
      console.log("token id is: " + tokenId)
      await icToken.methods.approve(marketAddr, tokenId).send({ from: account })
      .on('transactionHash', (txhash) => {
        setResult([...result, { step: pageState, hash: txhash }])
      })
      .on('confirmation', async () => {
        console.log("approved to market contract")
        setPageState(Steps.D)
        setIsLoading(false);
      })
    } catch(err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  const publish = async (e) => {
    e.preventDefault()
    setError('')
    
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      const marketContract = new web3.eth.Contract(SolvMarketSale, marketAddr)

      const numSales = await marketContract.methods.totalSalesOfICToken(icTokenAddr).call()

      let saleIdPostTx;

      if (isFixedPriceSale) {
        await marketContract.methods.publishFixedPrice(
          icTokenAddr,
          tokenId,
          paymentTokenAddr,
          intToWei(min),
          !max || max ? intToWei(saleAmount) : intToWei(max),
          parseDate(startTime),
          useAllowList,
          intToWei(startPrice)
        ).send({ from: account })
        .on('transactionHash', (txhash) => {
          setResult([...result, { step: pageState, hash: txhash }])
        })
        .on('confirmation', async () => {
          saleIdPostTx = await marketContract.methods.saleIdOfICTokenByIndex(icTokenAddr, numSales).call()
          console.log({
            saleTokenAddr,
            icTokenAddr,
            saleAmount,
            tokenId,
            paymentTokenAddr,
            min,
            startTime,
            startPrice,
            useAllowList,
            isFixedPriceSale,
            startBlock,
            saleIdPostTx
          })
          console.log("published fixed price sale")
          setStartBlock(await web3.eth.getBlockNumber())
          setSaleId(saleIdPostTx)
          setIsLoading(false);
        })
      } else {
        const duration = (parseDate(endTime) - parseDate(startTime))
        const intervalInSeconds = interval * intervalUnits
        await marketContract.methods.publishDecliningPrice(
          icTokenAddr,
          tokenId,
          paymentTokenAddr,
          intToWei(min),
          !max || max === 0 ? intToWei(saleAmount) : intToWei(max),
          parseDate(startTime),
          useAllowList,
          intToWei(startPrice),
          intToWei(lowestPrice),
          duration,
          intervalInSeconds
        ).send({ from: account })
        .on('transactionHash', (txhash) => {
          setResult([...result, { step: pageState, hash: txhash }])
        })
        .on('confirmation', async () => {
          saleIdPostTx = await marketContract.methods.saleIdOfICTokenByIndex(icTokenAddr, numSales).call()
          console.log({
            saleTokenAddr,
            icTokenAddr,
            saleAmount,
            maturities,
            percentages,
            tokenId,
            paymentTokenAddr,
            min,
            max,
            startTime,
            startPrice,
            lowestPrice,
            duration,
            interval,
            intervalUnits,
            intervalInSeconds,
            useAllowList,
            isFixedPriceSale,
            startBlock,
            saleIdPostTx
          })
          console.log("published declining price sale")
          setStartBlock(new BigNumber(await web3.eth.getBlockNumber()).toString())
          setSaleId(new BigNumber(saleIdPostTx).toString())
          setIsLoading(false);
        })
      }
    } catch(err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  const deploy = (e) => {
    return pageState === Steps.A ? approve(e) :
           pageState === Steps.B ? mint(e) : 
           pageState === Steps.C ? prepublish(e) :
           publish(e)
  }

  return (
    <section className={classes.details}>
      <Typography variant="h1" className={classes.smallTitle}>
        Step {Object.keys(Steps).find(k => Steps[k] === pageState)}: {pageState}
      </Typography>
      <form onSubmit={deploy} className={classes.form}>
        {pageState === Steps.A &&         
          <div className={classes.inputContainer}>
            <label htmlFor="id">Sale token address</label>
            <input
              type="text"
              placeholder="0x0197d7..."
              value={saleTokenAddr}
              onChange={(e) => setSaleTokenAddr(e.target.value)}
              required
            />
          </div>}

        {pageState === Steps.A && 
          <div className={classes.inputContainer}>
            <label htmlFor="id">Sale amount</label>
            <input
              type="text"
              placeholder="0"
              value={saleAmount}
              onChange={(e) => setSaleAmount(e.target.value)}
              required
            />
            {saleAmount && saleAmount !== 0 && `Amount in wei is: ${intToWei(saleAmount).toString()}`}
          </div>}

        {pageState === Steps.B && 
        <div className={classes.inputContainer}>
          <label htmlFor="id">Solv IC Token address for the sale token</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={icTokenAddr}
            onChange={(e) => setIcTokenAddr(e.target.value)}
            required
          />
        </div>}

        <div className={classes.inputContainerFlexRow}>
          {pageState === Steps.B && 
            <div className={classes.checkbox}>
            <label htmlFor="id">Any vesting?</label>
              <input
                type="checkbox"
                value={useVesting}
                onChange={(e) => setUseVesting(!useVesting)}
              />
            </div>}

          {pageState === Steps.B && useVesting && 
          <div>
            <Button
              onClick={(e)=>{setMaturities([...maturities, new Date()]); setPercentages([...percentages, 0]);}}
              className={`${classes.btn} ${classes.filled}`}
            >
              Add row
            </Button>
            <Button
              onClick={(e)=>{setMaturities(modifyArrElem(maturities, maturities.length-1)); setPercentages(modifyArrElem(percentages, percentages.length-1));}}
              className={`${classes.btn} ${classes.filled}`}
            >
              Remove row
            </Button>
          </div>}
        </div>

        <div className={classes.inputContainerFlexColumn}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
            {pageState === Steps.B && useVesting && 
              maturities.map((_, i) => 
                (<div key={i} className={classes.inputContainerFlexRow}>
                  <div className={classes.inputContainer}>
                    <label htmlFor="id">Percentage unlocked at {i}</label>
                    <input
                      type="number"
                      value={percentages[i]}
                      onChange={(e) => {setPercentages(modifyArrElem(percentages, i, parseInt(e.target.value)))}}
                      required
                    />
                  </div>
                  <div className={classes.inputContainerFlexRow} style={{marginTop:'35px', marginLeft:'80px'}}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label={`Date of ${i} unlock`}
                    format="MM/dd/yyyy"
                    value={maturities[i]}
                    onChange={(e) => setMaturities(modifyArrElem(maturities, i, e))}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                  }}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    id="time-picker"
                    label={`Time of ${i} unlock`}
                    value={maturities[i]}
                    onChange={(e) => setMaturities(modifyArrElem(maturities, i, e))}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </div>
              </div>))
            }
            </Grid>
          </MuiPickersUtilsProvider>
        </div>

        {pageState === Steps.C &&
          <div className={classes.inputContainer}>
            <label htmlFor="id">Market token address</label>
            <input
              type="text"
              placeholder="0x0197d7..."
              value={marketAddr}
              onChange={(e) => setMarketAddr(e.target.value)}
              required
            />
          </div>}

        {pageState === Steps.D &&
          <div className={classes.inputContainer}>
            <label htmlFor="id">Payment token address</label>
            <input
              type="text"
              placeholder="0x0197d7..."
              value={paymentTokenAddr}
              onChange={(e) => setPaymentTokenAddr(e.target.value)}
              required
            />
          </div>}

        {pageState === Steps.D &&
          <div className={classes.inputContainer}>
            <label htmlFor="id">Min purchaseable amount per purchase</label>
            <input
              type="number"
              value={min}
              onChange={(e) => setMin(e.target.value)}
              required
            />
            {min && min !== 0 && `Amount in wei is: ${intToWei(min).toString()}`}
          </div>}

        {pageState === Steps.D &&
          <div className={classes.inputContainer}>
            <label htmlFor="id">Max purchaseable amount per address (blank if no max)</label>
            <input
              type="number"
              value={max}
              onChange={(e) => setMax(e.target.value)}
            />
            {max && max !== 0 && `Amount in wei is: ${intToWei(max).toString()}`}
          </div>}
        
        {pageState === Steps.D &&
          <div className={classes.inputContainer}>
            <label htmlFor="id">Start Time</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Start date"
                  format="MM/dd/yyyy"
                  value={startTime}
                  onChange={(e) => setStartTime(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="Start time"
                  value={startTime}
                  onChange={(e) => setStartTime(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>}

        {pageState === Steps.D &&
          <div className={classes.checkbox}>
            <label htmlFor="id">Fixed Price Sale</label>
            <input
              type="checkbox"
              value={isFixedPriceSale}
              checked
              onChange={(e) => setIsFixedPriceSale(!isFixedPriceSale)}
            />
          </div>}

        {pageState === Steps.D && !isFixedPriceSale &&
          <div className={classes.inputContainer}>
            <label htmlFor="id">End Time</label>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="End date"
                  format="MM/dd/yyyy"
                  value={endTime}
                  onChange={(data) => setEndTime(data)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
                <KeyboardTimePicker
                  margin="normal"
                  id="time-picker"
                  label="End Time"
                  value={endTime}
                  onChange={(data) => setEndTime(data)}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </div>}

        {pageState === Steps.D &&
          <div className={classes.inputContainer}>
            <label htmlFor="id">Sale price at start</label>
            <input
              type="number"
              placeholder="0"
              value={startPrice}
              onChange={(e) => setStartPrice(e.target.value)}
              required
            />
            {startPrice && startPrice !== 0 && `Amount in wei is: ${intToWei(startPrice).toString()}`}
          </div>}

        {pageState === Steps.D && !isFixedPriceSale &&
          <div className={classes.inputContainer}>
            <label htmlFor="id">Sale price at end</label>
            <input
              type="text"
              placeholder="0"
              value={lowestPrice}
              onChange={(e) => {setLowestPrice(e.target.value)}}
            />
            {lowestPrice && lowestPrice!== 0 && `Amount in wei is: ${intToWei(lowestPrice).toString()}`}
          </div>}

        {pageState === Steps.D && !isFixedPriceSale &&
          <div className={classes.inputContainerFlexRow}>
            <div className={classes.inputContainer}>
              <label htmlFor="id">Interval between price drops</label>
              <input
                type="text"
                placeholder="10"
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
              />
            </div>
            <div className={classes.inputContainer} style={{marginLeft:'50px'}}>
              <label htmlFor="id">Interval units</label>
              <Select
                value={intervalUnits}
                onChange={e => setIntervalUnits(e.target.value)}
              >
                <MenuItem value={1}>Seconds</MenuItem>
                <MenuItem value={60}>Minutes</MenuItem>
                <MenuItem value={3600}>Hours</MenuItem>
                <MenuItem value={86400}>Days</MenuItem>
              </Select>
            </div>
          </div>}

        {pageState === Steps.D &&
          <div className={classes.checkbox}>
            <label htmlFor="id">Whitelisted sale?</label>
            <input
              type="checkbox"
              checked
              value={useAllowList}
              onChange={(e) => setUseAllowList(!useAllowList)}
            />
          </div>}

        {startBlock && 
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p className={classes.success}>{`Start block is: ${startBlock}`}
            </p>
          </div>}

        {saleId && 
          <div style={{ width: '100%', textAlign: 'center' }}>
            <p className={classes.success}>{`saleId block is: ${saleId}`}
            </p>
          </div>}

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading ? "Verifying..." : "Execute Transaction"}
          {isLoading && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      {result && result.map((e, i) => (<div key={i} style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.success}>{e.step} Transaction Successful {' → '}
          <a href={`${getNetworkLink(providerChainId)}/tx/${e.hash}`} target="blank" className={classes.link}>{e.hash}</a>
        </p>
      </div>))}

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
}


const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  inputContainerFlexColumn: {
    display:'flex',
    flexDirection:'column'
  },
  inputContainerFlexRow: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'center', 
    alignItems:'center'
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;