import React from "react";
import Blockies from "react-blockies";

import { useWeb3Context } from '../contexts/Web3Context';
// import { bsc_mainnet } from '../utils/metamask_popup';

import { makeStyles } from '@material-ui/core/styles';

const truncateAddress = (address) => {
  return address.slice(0, 6) + "..." + address.slice(-4);
};

const ConnectWallet = () => {
  const classes = useStyles();
  const { connectWeb3, disconnect, account } = useWeb3Context();

  // React.useEffect(() => {
  //   console.log(providerChainId)
  //   if (account && providerChainId !== 97 && providerChainId !== 56) bsc_mainnet()
  // }, [account, providerChainId])

  return (
    <button
      className={classes.walletBtn}
      onClick={account ? disconnect : connectWeb3}>
      <Blockies
        className={classes.img}
        seed={account ? account : ''}
      />
      <div>
        {account ? truncateAddress(account) : "Connect Wallet"}
      </div>
    </button>
  );
}

const useStyles = makeStyles((theme) => ({
  walletBtn: {
    background: '#3B185F',
    cursor: 'pointer',
    border: 0,
    outline: 'none',
    borderRadius: 36,
    height: '36px',
    lineHeight: '36px',
    padding: '0 18px 0 8px',
    display: 'flex',
    alignItems: 'center',
    color: 'white',

    "@media (max-width:599px)": {
      padding: 0,
    },

    '&:hover': {
      backgroundColor: '#2A0944',
    },

    '& div': {
      "@media (max-width:599px)": {
        margin: '0 8px 0 4px',
      },
    }
  },
  img: {
    borderRadius: '12px',
    marginRight: 5,
    height: '24px !important',
    width: '24px !important',

    "@media (max-width:599px)": {
      marginRight: 0,
      height: '36px !important', width: '36px !important',
      borderRadius: '20px',
    },
  }
}));

export default ConnectWallet;