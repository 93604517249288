export const checkError = (error) => {
  if (error.message.includes("Returned values aren't valid")) {
    return 'Invalid address. Check network and try again.';
  } else if (error.message) {
    return error.message;
  } else if (error.response) {
    return error.response.data.error;
  } else {
    return error;
  }
}