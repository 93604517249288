import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Web3 from "web3";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { useWeb3Context } from "../../../../contexts/Web3Context";
import { getNetworkLink, MAX_INT, numberWithCommas } from "../../../../utils/parse";
import { checkError } from "../../../../utils/checkError";
import SmartChefFactory from "../../../../utils/abi/SmartChefFactory.json";
import { useEffect } from "react";

const Form = () => {
  const classes = useStyles();
  
  const { account, provider, providerChainId } = useWeb3Context();
  const web3 = new Web3(provider);

  const [contractAddr, setContractAddr] = useState("");
  const [txHash, setTxHash] = useState("");
  const [error, setError] = useState("");

  const [factoryAddress, setFactoryAddress] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [rewardTokenAddress, setRewardTokenAddress] = useState("");
  const [rewardPerSecond, setRewardPerSecond] = useState(0);
  const [rewardPerSecondWei, setRewardPerSecondWei] = useState(0);
  const [startTimeVal, setStartTimeVal] = useState(new Date());
  const [bonusEndTimeVal, setBonusEndTimeVal] = useState(new Date());
  const [admin, setAdmin] = useState(account);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAdmin(account);
  }, [account]);

  useEffect(() => {
    setRewardPerSecondWei(web3.utils.toWei(String(rewardPerSecond || 0)))
  } , [rewardPerSecond, web3.utils])

  const deploy = async (e) => {
    e.preventDefault();
    setError("");
    const startTime =
      Date.parse(startTimeVal.toString().split(" G")[0] + "z") / 1000;
    const bonusEndTime =
      Date.parse(bonusEndTimeVal.toString().split(" G")[0] + "z") / 1000;
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      let smartChefFactoryContract = new web3.eth.Contract(
        SmartChefFactory.abi,
        factoryAddress
      );
      await smartChefFactoryContract.methods
        .deployPool(
          stakingTokenAddress,
          rewardTokenAddress,
          rewardPerSecondWei,
          startTime,
          bonusEndTime,
          web3.utils.toWei(MAX_INT, 'ether'),
          admin
        )
        .send({ from: account })
        .on("transactionHash", (transactionHash) => {
          console.log("transactionHash", transactionHash);
          setTxHash(transactionHash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          console.log("contractAddress", receipt.contractAddress);
          setContractAddr(receipt.contractAddress);
        });
      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  };

  return (
    <section className={classes.details}>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Factory Addresses</label>
          <input
            type="text"
            value={factoryAddress}
            onChange={(e) => setFactoryAddress(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">Staking token addresses</label>
          <input
            type="text"
            value={stakingTokenAddress}
            onChange={(e) => setStakingTokenAddress(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">reward token addresses</label>
          <input
            type="text"
            value={rewardTokenAddress}
            onChange={(e) => setRewardTokenAddress(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">reward per second</label>
          <input
            type="number"
            value={rewardPerSecond}
            onChange={(e) => setRewardPerSecond(e.target.value)}
            required
          />
          <label style={{ color: "#2C394B", fontSize: 14 }}>wei: {numberWithCommas(rewardPerSecondWei)}</label>
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Start Time</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="MM/dd/yyyy"
                value={startTimeVal}
                onChange={(data) => setStartTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={startTimeVal}
                onChange={(data) => setStartTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Bonus End Time</label>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justifyContent="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Date picker dialog"
                format="MM/dd/yyyy"
                value={bonusEndTimeVal}
                onChange={(data) => setBonusEndTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardTimePicker
                margin="normal"
                id="time-picker"
                label="Time picker"
                value={bonusEndTimeVal}
                onChange={(data) => setBonusEndTimeVal(data)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="hash">Admin</label>
          <input
            type="text"
            value={admin}
            onChange={(e) => setAdmin(e.target.value)}
            required
          />
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${
            isLoading && classes.btnWithLoader
          }`}
        >
          {isLoading ? "Verifying..." : "Deploy Factory"}
          {isLoading && (
            <CircularProgress className={`${classes.loading}`} size={24} />
          )}
        </Button>
      </form>

      {txHash && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.success}>
            Tx hash {" → "}
            <a
              href={`${getNetworkLink(providerChainId)}/tx/${txHash}`}
              target="blank"
              className={classes.link}
            >
              {txHash}
            </a>
          </p>
        </div>
      )}

      {contractAddr && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.success}>
            Deployed contract address {" → "}
            <a
              href={`${getNetworkLink(
                providerChainId
              )}/address/${contractAddr}`}
              target="blank"
              className={classes.link}
            >
              {contractAddr}
            </a>
          </p>
        </div>
      )}

      {error && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <p className={classes.error}>{error}</p>
        </div>
      )}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    maxWidth: 1200,
    margin: "auto",
    textAlign: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: "black",
    marginTop: 30,
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all",
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline",
  },
  error: {
    color: "red",
  },
}));

export default Form;
