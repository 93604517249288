import React from "react";

import Navbar from "../components/Navbar";
import HeroSection from "../components/CheckSolvActionsIDO2/Hero"
import Form from '../components/CheckSolvActionsIDO2/Form';
import Footer from "../components/Footer";

const PublishIDO2 = () => {
  return (
    <React.Fragment>
      <Navbar />
      <main style={{ minHeight: '80vh' }}>
        <HeroSection />
        <Form />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default PublishIDO2;