import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";
import IFAllocationMaster from "../../utils/abi/IFAllocationMaster.json";
import { checkError } from "../../utils/checkError";
import { multipliedBy, toPlainString, numberWithCommas, MAX_INT, getNetworkLink } from "../../utils/parse";

const Form = () => {
  const classes = useStyles();
  const { account, provider, providerChainId } = useWeb3Context();

  const [allocationMaster, setAllocationMaster] = useState('');
  const [trackName, setTrackName] = useState('');
  const [token, setToken] = useState('');
  const [war, setWar] = useState('');
  const [prr, setPrr] = useState('');
  const [arr, setArr] = useState('');
  const [maxTotalStakeVal, setMaxTotalStakeVal] = useState(MAX_INT);
  const [maxTotalStakedWei, setMaxTotalStakedWei] = useState(MAX_INT);
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const deploy = async (e) => {
    e.preventDefault()
    setResult('');
    setError('');
    if (!provider) return;
    try {
      setIsLoading(true);
      const web3 = new Web3(provider);
      const allocationMasterContract = new web3.eth.Contract(IFAllocationMaster.abi, allocationMaster);
      // get track number before adding
      const trackNum = await allocationMasterContract.methods.trackCount().call();
      // add track
      const maxTotalStake = web3.utils.toWei(maxTotalStakeVal, 'ether');
      console.log(maxTotalStake);
      let txhash = await allocationMasterContract.methods
        .addTrack(
          trackName, // name
          token, // stake token
          war, // weight accrual rate
          prr, // passive rollover rate
          arr, // active rollover rate,
          maxTotalStake // maximum user can stake
        )
        .send({ from: account });
      setResult(txhash.transactionHash);

      // log
      console.log('Track name:', trackName)
      console.log('Token:', token)
      console.log('Weight accrual rate:', war)
      console.log('Passive rollover rate:', prr)
      console.log('Active rollover rate:', arr)
      console.log('Maximum total stake', maxTotalStake)
      console.log('---- Output ----')
      console.log('Tx hash:', result.hash)
      console.log('Track number:', trackNum)
      console.log('Tx hash:', txhash.transactionHash)
      setIsLoading(false);
    } catch (err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  return (
    <section className={classes.details}>
      <form onSubmit={deploy} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Allocation Master Contract address</label>
          <input
            type="text"
            placeholder="0x0197d7..."
            value={allocationMaster}
            onChange={(e) => setAllocationMaster(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Track Name</label>
          <input
            type="text"
            placeholder="IDIA Token"
            value={trackName}
            onChange={(e) => setTrackName(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Stake Token address</label>
          <input
            type="hash"
            placeholder="0x421385..."
            value={token}
            onChange={(e) => setToken(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Weight Accrual Rate</label>
          <input
            type="text"
            placeholder="10000000"
            value={war}
            onChange={(e) => setWar(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Passive Rollover Rate</label>
          <input
            type="text"
            placeholder="0"
            value={prr}
            onChange={(e) => setPrr(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Active Rollover Rate</label>
          <input
            type="text"
            placeholder="0"
            value={arr}
            onChange={(e) => setArr(e.target.value)}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label htmlFor="hash">Max total Stake</label>
          <input
            type="text"
            placeholder="1000000"
            value={maxTotalStakeVal}
            onChange={(e) => {
              setMaxTotalStakeVal(e.target.value);
              try {
                let web3 = new Web3(provider);
                setMaxTotalStakedWei(web3.utils.toWei(e.target.value, 'ether'));
              } catch (err) {
                console.error("Max total stake has many decimals", err);
                setMaxTotalStakedWei(toPlainString(multipliedBy(e.target.value).toString()));
              }
            }}
            required
          />
          <label style={{ color: "#2C394B", fontSize: 14 }}>wei: {numberWithCommas(maxTotalStakedWei)}</label>
        </div>

        <Button
          type="submit"
          disabled={!isLoading ? false : true}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading ? "Verifying..." : "Add Track"}
          {isLoading && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      {result && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.success}>Transfer Successful {' → '}
          <a href={`${getNetworkLink(providerChainId)}/tx/${result}`} target="blank" className={classes.link}>{result}</a>
        </p>
      </div>}

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
};

const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;