import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BigNumber from "bignumber.js";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';

import Web3 from "web3";
import { useWeb3Context } from "../../contexts/Web3Context";

import SolvMarketSale from "../../utils/abi/SolvICMarket.json"
import { checkError } from "../../utils/checkError";
import { marketAddresses } from "../../utils/constants";

const FACTOR = 5000; // factor is number of blocks we use to calculate average block time for the chain

const Form = () => {
  const classes = useStyles();
  const [error, setError] = useState('');
  const { provider, providerChainId } = useWeb3Context();
  const [isLoading, setIsLoading] = useState(false);
  const [numParticipants, setNumParticipants] = useState(0)
  const [totalSales, setTotalSales] = useState(new BigNumber(0))
  const [marketAddr, setMarketAddr] = useState('')
  const [decimals, setDecimals] = useState(18)
  
  const [saleId, setSaleId] = useState('')
  const [startTime, setStartTime] = useState()
  const [startBlock, setStartBlock] = useState(0)

  const [numQueries, setNumQueries] = useState(0)
  const [totalQueries, setTotalQueries] = useState(0)

  useEffect(() => {
    setMarketAddr(marketAddresses[providerChainId])
  }, [providerChainId])

  useEffect(() => {
    const getStartBlock = async () => { 
      setIsLoading(true)

      const web3 = new Web3(provider);
      let currBlock = await web3.eth.getBlockNumber()
      let currTimestamp = (await web3.eth.getBlock(currBlock)).timestamp
      console.log(`curr block is ${currBlock}, curr timestmap is ${currTimestamp}`)
      const blockTime = (currTimestamp - (await web3.eth.getBlock(currBlock - FACTOR)).timestamp)/FACTOR
      console.log(`block time is: ${blockTime}`)

      while(currTimestamp > startTime.getTime()/1000) {
          console.log(`start time is: ${startTime.getTime()/1000}`)
          currBlock -= 
            Math.max(
              1, 
              parseInt(
                (currTimestamp - startTime.getTime()/1000)/blockTime)
            ) // always reduces by at least 1
          currTimestamp = (await web3.eth.getBlock(currBlock)).timestamp
      }

      console.log(`final block is ${currBlock}, final timestmap is ${currTimestamp}`)

      setStartBlock(currBlock)
      setIsLoading(false)
    }
    provider && startTime && getStartBlock()
  }, [provider, startTime])
  
  const isAddress = (address) => (/^(0x)?[0-9a-f]{40}$/i.test(address))

  const generate = async (e) => {
    e.preventDefault()

    try {
      const web3 = new Web3(provider);
      setIsLoading(true)
      if (!isAddress(marketAddr)) {
        const error = "Market token address is not a valid EVM address"
        console.error(error)
        setError(error)
        setIsLoading(false)
      }
      const marketContract = new web3.eth.Contract(SolvMarketSale, marketAddr)
      const participants = new Set()
      let totalAmount = new BigNumber(0)

      const currBlock = await web3.eth.getBlockNumber()

      const localTotalQueries = Math.ceil((currBlock - startBlock)/5000)
      setTotalQueries(localTotalQueries)
    
      const fetchData = async (i) => new Promise(res => {
        setTimeout(async ()=>{
          console.log(`in loop ${i+1} of ${localTotalQueries}`)
          setNumQueries(i+1)
          const call = await marketContract.getPastEvents("Traded", {
            filter: { saleId },
            fromBlock: startBlock + 5000 * i,
            toBlock: Math.min(startBlock + 5000 * i + 4999, currBlock)
          })
          call.forEach(e => {
            participants.add(e.returnValues.buyer)
            totalAmount = totalAmount.plus(e.returnValues.tradedAmount)
          })
          res()
        }, 300 * i)}
      )

      Promise.all([...Array(localTotalQueries).keys()].map(fetchData))
      .then(res => {
        setNumParticipants(participants.size)
        setTotalSales(totalAmount)
        setIsLoading(false)
      })

    } catch(err) {
      const error = checkError(err);
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  }

  return (
    <section className={classes.details}>
      <form onSubmit={generate} className={classes.form}>
        <div className={classes.inputContainer}>
          <label htmlFor="id">Sale id</label>
          <input
            type="text"
            value={saleId}
            onChange={(e) => setSaleId(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
          <label htmlFor="id">Sale token decimals</label>
          <input
            type="number"
            value={decimals}
            default={18}
            onChange={(e) => setDecimals(e.target.value)}
            required
          />
        </div>

        <div className={classes.inputContainer}>
        <label htmlFor="id">Start time</label>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date picker dialog"
              format="MM/dd/yyyy"
              value={startTime}
              onChange={(data) => setStartTime(data)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              required
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time picker"
              value={startTime}
              onChange={(data) => setStartTime(data)}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
              required
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>

        <Button
          type="submit"
          disabled={isLoading}
          className={`${classes.btn} ${classes.filled} ${isLoading && classes.btnWithLoader}`}
        >
          {isLoading && (numQueries !== 0 || totalQueries !== 0) ? `Progress: ${numQueries}/${totalQueries}`: "Generate Stats"}
          {isLoading && (numQueries !== 0 || totalQueries !== 0) && <CircularProgress className={`${classes.loading}`} size={24} />}
        </Button>
      </form>

      {numQueries === totalQueries && numQueries !== 0 && !isLoading &&
        <Typography variant="h1" className={classes.smallTitle}>
            Number of participants: {numParticipants.toString()} <br/>
            Total Sales: {totalSales.dividedBy(Math.pow(10, 18)).toString()}
        </Typography>
      }

      {error && <div style={{ width: '100%', textAlign: 'center' }}>
        <p className={classes.error}>{error}</p>
      </div>}
    </section>
  );
}


const useStyles = makeStyles((theme) => ({
  ...theme.overrides.formStyle,
  details: {
    position: "relative",
    overflow: "hidden",
    width: '100%',
    maxWidth: 1200,
    margin: 'auto',
    textAlign: 'center',
  },
  title: {
    fontSize: 32,
    fontWeight: 900,
    color: 'black',
    marginTop: 30
  },
  smallTitle: {
    fontSize: 22,
    marginTop: 20,
    fontWeight: 700,
  },
  success: {
    color: "#1F3C88",
    fontWeight: 700,
    wordBreak: "break-all"
  },
  link: {
    color: "#6ECB63",
    textDecoration: "underline"
  },
  error: {
    color: "red"
  },
}));

export default Form;