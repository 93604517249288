import React, { useCallback, useContext, useEffect, useState } from 'react';

import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

export const Web3Context = React.createContext({});
export const useWeb3Context = () => useContext(Web3Context);

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: 'f0493a02561f4419be93dde46cc584c6',
    }
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
});

export const Web3Provider = ({ children }) => {
  const [web3State, setWeb3State] = useState({});
  const { providerChainId, provider, account } = web3State;
  const [loading, setLoading] = useState(true);

  const setWeb3Provider = useCallback(async (prov, initialCall = false) => {
    try {
      if (prov) {
        const web3Provider = new Web3(prov);

        if (initialCall) {
          const gotAccount = await web3Provider.eth.getAccounts();
          setWeb3State(_old => ({
            account: gotAccount[0],
            provider: web3Provider,
            providerChainId: Number(prov.chainId),
          }));
        } else {
          setWeb3State(_old => ({
            ..._old,
            provider: web3Provider,
            providerChainId: Number(prov.chainId),
          }));
        }
      }
    } catch (error) {
      console.log({ web3ModalError: error });
    }
  }, []);

  const connectWeb3 = useCallback(async () => {
    try {
      setLoading(true);
      console.log("connecting..")

      const modalProvider = await web3Modal.connect();
      console.log(modalProvider)

      await setWeb3Provider(modalProvider, true);

      // Subscribe to accounts change
      modalProvider.on('accountsChanged', accounts => {
        setWeb3State(_old => ({
          ..._old,
          account: accounts[0],
        }));
      });

      // Subscribe to chainId change
      modalProvider.on('chainChanged', _chainId => {
        setWeb3Provider(modalProvider);
      });
    } catch (error) {
      console.log({ web3ModalError: error });
    }
    setLoading(false);
  }, [setWeb3Provider]);

  const disconnect = useCallback(async () => {
    web3Modal.clearCachedProvider();
    setWeb3State({});
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.autoRefreshOnNetworkChange = false;
    }
    if (web3Modal.cachedProvider) {
      connectWeb3();
    } else {
      setLoading(false);
    }
  }, [connectWeb3]);

  return (
    <Web3Context.Provider
      value={{
        provider,
        connectWeb3,
        loading,
        disconnect,
        providerChainId,
        account
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
