import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import theme from "./theme";
import { Web3Provider } from "./contexts/Web3Context";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Web3Provider>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Web3Provider>
  </ThemeProvider>,
  document.querySelector("#root")
);
